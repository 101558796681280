import clsx from 'clsx'
import {FC, useState, useEffect, useContext} from 'react'
import {KTSVG} from '../../../helpers'
import {HeaderNotificationsMenu , HeaderUserMenu} from '../../../partials'
// HeaderNotificationsMenu
import {Badge, Spin} from 'antd'
import {mainConfig} from '../../../../../src/config/mainConfig'
import AuthContext from '../../../../context/authContext'
import { getUser } from '../../../../app/pages/mainPages/CommonFunction'
// import HeaderNotificationsMenu from '../../../partials/layout/header-menus/HeaderNotificationsMenu'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

let token = ''

const Topbar: FC = (props:any) => {
  

  const [FirstName, setFirstName] = useState<any>('')
  const [LastName, setLastName] = useState<any>('')
  const [loader,setLoader]=useState<any>(false)
  

  const [NotifCount, setNotifCount] = useState<any>('')

  const [NotifData, setNotifData] = useState<any[]>([])
  const [flag,setFlag]=useState<any>(false);
  const context = useContext(AuthContext);
  // console.log('context :::Topbar::',context)

  useEffect(() => {
    getAllNotificationData()
    getAllProfileData()
      }, [])
     token = JSON.parse(localStorage.getItem('portalUserDataToken') || '')
  const getAllProfileData = ()=>{
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/view-profile`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(async function (response) {
        if (response.status != 200) {
          return
        }
        if (((!context?.allUsers) || (context?.allUsers.length == 0))) {
          let userData:any =await  getUser()
          context.allUsers=userData
        }
        if (!context?.userData) {
          let userData:any=localStorage.getItem('userData')
          // console.log('userData :::',userData)
          if (userData) {
            context.userData=JSON.parse(userData)
            
          }
        }
        response.json().then(function (data) {
          setFirstName(data.data.userProfle.firstname)
          setLastName(data.data.userProfle.lastname)
        })
      })
      .catch(function (err) {
      })
  }
  const getAllNotificationData = ()=>{
    setLoader(true)
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/get-activity-logs`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        if (response.status != 200) {
          return
        }
        response.json().then(function (data) {
          setNotifCount(data?.data?.logs?.length)
          setNotifData(data?.data?.logs)
        })
        setLoader(false)
      })
      .catch(function (err) {
      })
      // setFlag(true)
  }
  
  return (
    <div className='d-flex align-items-stretch flex-shrink-0' style={{backgroundColor: '#ffffff'}}>
      
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
       
      </div>
     
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      
      </div>
    
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
       
      </div>

     
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        
      </div>

      
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)} >
       
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
         onClick={()=>{
          if (flag==false) {
            getAllNotificationData()
            setFlag(true)
          }else{
            setFlag(false)
          }
        }}
        style={{marginRight:'20px'}}
        >
          <Badge count={NotifCount} color='red'>
            <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            />
          </Badge>
          
        </div>
        {/* <HeaderNotificationsMenu   NotifCount={NotifCount} setNotifData={setNotifData} NotifData={NotifData}/> */}
    <HeaderNotificationsMenu   NotifCount={NotifCount} loader={loader} NotifData={NotifData} updateData={getAllNotificationData}/>
      </div>

     
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
       
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span>Hi, {FirstName} </span>
        
        </div>
        <HeaderUserMenu />
      
      </div>
     
    </div>
  )
}

export {Topbar}
