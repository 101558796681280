

import React, {FC, useContext, useEffect} from 'react'
import {Switch} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {Login} from '../pages/mainPages/Login'
import {ResetPassword} from '../pages/mainPages/ResetPassword'
import {ForgotPassword} from '../pages/mainPages/ForgotPassword'
import AuthContext from '../../context/authContext'
import { getUser } from '../pages/mainPages/CommonFunction'

let token: any
let attempt: any
let url: any
let url1: any
const Routes: FC = () => {
  url = window.location.href.split('#/')[1]
  url1 = url.split('?')[0]
  token = localStorage.getItem('portalUserDataToken')
  attempt = localStorage.getItem('portalUserAttempt')
  const context = useContext(AuthContext);
  useEffect(()=>{
    
    setAllContextData()
  },[])
  const setAllContextData =async ()=>{
    try {
    if (((!context?.allUsers) || (context?.allUsers.length == 0))) {
      let userData:any =await  getUser()
      // console.log('context :::Topbar::',userData)
      if (userData) {
        context.allUsers=userData
      }
    }
  } catch (error) {
    console.log('error in set context::',error)
  }
  }
  return (
    <React.Fragment>
      <Switch>
        {token == '' || token == 'undefined' || token == null ? (
          <React.Fragment>
          
            {url1 == 'reset-password' ? (
              <ForgotPassword />
            ) : attempt != 'true' ? (
              <Login />
            ) : (
              <ResetPassword />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </React.Fragment>
        )}
      </Switch>
      <MasterInit />
    </React.Fragment>
  )
}

export {Routes}
