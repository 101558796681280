/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import {Link, withRouter} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

import {mainConfig} from '../../../config/mainConfig'
let token = ''


const TagListWrapper=(props: any)=> {


  const [loading, setloading] = useState<any>(false)

  const [historicalDetails, setHistoricalDetails] = useState<any[]>([])

  useEffect(() => {}, [])

  const getSupplier=(data1: any)=> {
    try {
      fetch(`${mainConfig.MAIN_ENDPOINT}/api/tag-with-all-products/${data1.tag_id}`, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(function (response) {
          if (response.status != 200) {
            localStorage.clear()
            window.location.reload()
          }

          response.json().then(function (data) {})
        })
        .catch(function (err) {
          setloading(false)
        })
    } catch (err) {}
  }

  token = JSON.parse(localStorage.getItem('portalUserDataToken') || '')

  return (
    <React.Fragment>
      {loading == true ? (
        <div style={{marginLeft: '50%'}}>
          <div className='spinner-border m-2' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <ol className='breadcrumb breadcrumb-dot fs-6 fw-bold' style={{margin: '1%'}}>
            {/* <Link to='/home' className='pe-1 cursor-pointer'>
              <li className='breadcrumb-item pe-1'>Home</li>
            </Link>
            / &nbsp;<li className='breadcrumb-item pe-3'>Tag List</li> */}
          </ol>

          <div className='row g-5 gx-xxl-8' style={{marginTop: '1%'}}>
            <div className='col-xxl-12'>
              <div className='card-xxl-stretch mb-5 mb-xxl-8'>
                <div className='row gy-5 g-xl-4'>
                  <div className={`card`}>
                    <div className='card-header border-0 pt-2'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span
                          className='card-label fw-bolder fs-3 mb-1'
                          style={{color: 'rgb(138, 38, 80)'}}
                        >
                          Tag List
                        </span>
                      </h3>
                      <div className='card-toolbar'></div>
                    </div>

                    <div className='card-body py-3 '>
                      <div className='table-responsive h-300px'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <tbody>
                            {historicalDetails != undefined &&
                              historicalDetails.map((j) => {
                                return (
                                  <tr>
                                    <td></td>
                                    <td>
                                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                        {j.productcode}
                                      </span>
                                    </td>
                                    <td>
                                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                        {j.child_code ? j.child_code : '-'}
                                      </span>
                                    </td>
                                    <td>
                                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                        {j.description}
                                      </span>
                                    </td>

                                    <td>
                                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                        {j.price ? j.price : '-'}
                                      </span>
                                    </td>

                                    <td>
                                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                        {j.forecast ? j.forecast : '-'}
                                      </span>
                                    </td>

                                    <td>
                                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                        {j.comments ? j.comments : '-'}
                                      </span>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='modal fade' id='po' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
              <div className='modal-content'>
                <div className='modal-header pb-0 border-0 justify-content-end'>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-primary'
                    data-bs-dismiss='modal'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>

                <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                  <div className='text-center mb-13'>
                    <h1 className='mb-3'>Purchase Order</h1>
                  </div>
                  <form className='form'>
                    <div className='fv-row mb-10'>
                      <label className='form-label fs-6 fw-bolder text-dark text-left'>
                        Quantity :
                      </label>
                      <input
                        type='email'
                        className='form-control form-control-solid'
                        placeholder=''
                      />
                    </div>

                    <div className='fv-row mb-10'>
                      <label className='form-label fs-6 fw-bolder text-dark text-left'>
                        Price :
                      </label>
                      <input
                        type='email'
                        className='form-control form-control-solid'
                        placeholder=''
                      />
                    </div>

                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-lg btn-primary w-40 mb-5'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default withRouter(TagListWrapper)
