/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
   
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
       
        <div className='text-dark order-2 order-md-1'>
         
        © Copyright <span className='fw-bold me-1'>{new Date().getFullYear()} </span> Sigma. All Rights Reserved Designed by  <a href="https://www.webdezign.co.uk" target="_blank" className='text-blue-800 text-hover-primary'>
           Active Webdezign
          </a>
        </div>
     
      </div>
   
    </div>
  )
}

export {Footer}
