import { useState, useEffect } from 'react';
import { mainConfig } from '../../../config/mainConfig';

let token =  localStorage.getItem('portalUserDataToken') ? JSON.parse( localStorage.getItem('portalUserDataToken') || '') : ''
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
export const roundToOne = (num: number): number => {
  return +num.toFixed(2);
};
export const getUser =  async (): Promise<any[]>=> {
  return new Promise((resolve, reject) => {
    
    if (!token) {
      token=localStorage.getItem('portalUserDataToken')
      token= token?.slice(1, -1)
      //  console.log('token ! :::::',token)
      // resolve([])
      // return
    }
    // token.replaceAll(/"/g, '');
    // let tote1:any=localStorage.getItem('portalUserDataToken')
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/get-users`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        if (response.status != 200) {
          resolve([])
          return
        }
        response.json().then(function (data) {
          // console.log('::::::::::::::::data :::',data.data.users)
          resolve(data.data.users)
        })
      })
      .catch(function (err) {
        // console.log('::::::::::::::::err users:::',err)
        resolve([])
      })
  })
  
}

