import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Link, Redirect} from 'react-router-dom'
import {message} from 'antd'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from '../../routing/PrivateRoutes'
import {mainConfig} from '../../../config/mainConfig'

export const ForgotPassword=()=> {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const [toProduct, setToProduct] = useState(false)

  const [ErrNewPassword, setErrNewPassword] = useState<any>(false)
  const [ErrVerPassword, setErrVerPassword] = useState<any>(false)
  const [PasswordErr, setPasswordErr] = useState<any>(false)

  const [userEmail, setuserEmail] = useState<any>('')
  const [NewToken, setNewToken] = useState<any>('')

  const [CurrentPass, setCurrentPassword] = useState<any>('')
  const [NewPassword, setNewPassword] = useState<any>('')
  const [VerPassword, setVerPassword] = useState<any>('')

  useEffect(() => {
    let temp = window.location.href.split('token=')[1]

    let temp1 = temp.split('email=')
    let email = temp1[1]
    let token = temp1[0]

    setuserEmail(email)
    setNewToken(token)
  }, [])

  const handleSubmit=(e: any)=> {
    e.preventDefault()

    if (NewPassword != '' && VerPassword != '') {
      if (NewPassword == VerPassword) {
        setPasswordErr(false)

        const user = {
          token: `${NewToken}`,
          email: `${userEmail}`,
          password: `${NewPassword}`,
          password_confirmation: `${VerPassword}`,
        }
        try {
          fetch(`${mainConfig.MAIN_ENDPOINT}/api/reset-password`, {
            method: 'Post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
          })
            .then(function (data) {
              data.json().then(function (data) {
                setCurrentPassword('')
                setNewPassword('')
                setVerPassword('')
                if (data.success == true) {
                  message.success(data.message)
                } else {
                  message.error(data.message)
                  setCurrentPassword('')
                  setNewPassword('')
                  setVerPassword('')
                }
              })
              if (data.status == 204) {
                setCurrentPassword('')
                setNewPassword('')
                setVerPassword('')

                message.error('Current Password is Incorrect ! ')
              }
            })
            .catch(function (error) {})
        } catch (err) {}
      } else {
        setPasswordErr(true)
      }
    } else {
      if (NewPassword == '') {
        setErrNewPassword(true)
      }
      if (VerPassword == '') {
        setErrVerPassword(true)
      }
    }
  }

  const onToLogin=()=> {
    const myTimeOut=setTimeout(function () {
      window.location.reload()
    }, 1000)
    clearTimeout(myTimeOut)
  }

  return (
    <React.Fragment>
      {toProduct == true ? (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      ) : (
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={handleSubmit}
              >
                <div className='text-center mb-10'>
                  <h1 className='text-dark mb-3'>Reset Password </h1>
                </div>

                {hasErrors === true && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                      Sorry, looks like there are some errors detected, please try again.
                    </div>
                  </div>
                )}

                {hasErrors === false && (
                  <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>Sent password reset. Please check your email</div>
                  </div>
                )}

                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>New Password</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        onChange={(e) => setNewPassword(e.target.value)}
                        onFocus={() => setErrNewPassword(false)}
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='New Password'
                      />
                    </div>
                    {ErrNewPassword == true ? (
                      <span role='alert' className='text-danger'>
                        New Password is Required
                      </span>
                    ) : null}
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Verify Password</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        onChange={(e) => setVerPassword(e.target.value)}
                        onFocus={() => setErrVerPassword(false)}
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='
                                  Verify Password'
                      />
                    </div>
                    {ErrVerPassword == true ? (
                      <span role='alert' className='text-danger'>
                        Password is Required
                      </span>
                    ) : null}
                  </div>
                </div>
                {PasswordErr == true ? (
                  <span
                    role='alert'
                    style={{marginLeft: '8%', marginTop: '-2%'}}
                    className='text-danger'
                  >
                    New Password & Verify Password does not match
                  </span>
                ) : null}

                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder me-4'
                  >
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>

                  <Link to='/login' onClick={onToLogin}>
                    <button type='button' className='btn btn-lg btn-light-primary fw-bolder'>
                      Login
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
